import { BrowserRouter, Routes, Route } from 'react-router-dom'; 
import Login from '../src/Login/Login';
import Home from '../src/Home/Home'

const App = () =>{
    return (
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />  {/* Route for Home */}
          <Route path="/Home" element={<Home />} />  {/* Route for Home */}
          <Route path="/Login" element={<Login />} />  {/* Route for Home */}
          {/* Add other routes here */}
        </Routes>
      </BrowserRouter>
    )
}
export default App;