import React from 'react';
import './home.scss'; // Assuming you have a CSS file for styles
import { useNavigate } from 'react-router-dom';

const EmployeeHome = () => {
  const navigate = useNavigate();

  const handleSignout = (event) => {
    event.preventDefault();
    navigate("/Login");
  };

  // Updated announcement data with dates
  const announcements = [
    { id: 1, title: 'Announcement 1', description: 'Description of announcement 1', date: '2024-09-20' },
    { id: 2, title: 'Announcement 2', description: 'Description of announcement 2', date: '2024-09-18' },
    { id: 3, title: 'Announcement 3', description: 'Description of announcement 3', date: '2024-09-15' },
  ];

  const schedules = [
    '9 AM - Team Meeting',
    '11 AM - Project Update',
    '2 PM - Client Call',
  ];

  const uploadedFiles = [
    {
      id: 1,
      name: 'File 1',
      url: '/path/to/file1.pdf',
      size: '1.2 MB',
      uploadDate: '2024-09-20',
    },
    {
      id: 2,
      name: 'File 2',
      url: '/path/to/file2.pdf',
      size: '800 KB',
      uploadDate: '2024-09-18',
    },
    {
      id: 3,
      name: 'File 3',
      url: '/path/to/file3.pdf',
      size: '2.5 MB',
      uploadDate: '2024-09-15',
    },
  ];

  return (
    <div className="home-container">
      <header className="home-header">
        <h1>Welcome, John Doe</h1>
        <button onClick={handleSignout} className="logout-button">Logout</button>
      </header>

      <main className="main-content">
        {/* Updated section for announcements */}
        <section className="content-section">
          <h2>Announcements</h2>
          <div className="content-grid">
            {announcements.map(item => (
              <div key={item.id} className="content-card">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p className="announcement-date">{item.date}</p> {/* Added date for announcements */}
              </div>
            ))}
          </div>
          <div className="see-more">
            <a href="">See More</a>
          </div>
        </section>

        <section className="schedule-section">
          <h2>Your Schedule</h2>
          <ul className="schedule-list">
            {schedules.map((schedule, index) => (
              <li key={index}>{schedule}</li>
            ))}
          </ul>
          <div className="see-more">
            <a href="">See More</a>
          </div>
        </section>

        <section className="files-section">
          <h2>Uploaded Files</h2>
          <div className="files-grid">
            {uploadedFiles.map(file => (
              <div key={file.id} className="file-card">
                <a href={file.url} target="_blank" rel="noopener noreferrer" className="file-name">{file.name}</a>
                <div className="file-details">
                  <span className="file-size">{file.size}</span>
                  <span className="file-date">{file.uploadDate}</span>
                </div>
              </div>
            ))}
          </div>
          <div className="see-more">
            <a href="">See More</a>
          </div>
        </section>
      </main>

      <footer id="contact" className="footer">
        <p>For inquiries, contact us at <a href="mailto:info@jetsondoro.com">info@jetsondoro.com</a></p>
      </footer>
    </div>
  );
};

export default EmployeeHome;
